.google-ads {
  display: block;
}

@media (min-width: 600px) {
  .google-ads {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 1100px) {
  .pharmacy-table {
    @apply grid grid-cols-2;
  }
}

@media (min-width: 1100px) {
  .pharmacy-table {
    @apply grid grid-cols-4 gap-5;
  }
}
