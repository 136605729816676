.pharmacy-card {
  /*height: 300px;
  overflow: hi;*/
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.08);
}

@media (max-width: 500px) {
  .label-container {
    @apply w-3/12;
  }
  .content-container {
    @apply w-9/12;
  }
}

@media (width > 600px) {
  .pharmacy-card .address {
    height: 50px;
  }
}
