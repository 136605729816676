.modal-container {
  @apply bg-white text-black;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 4;
}

.button {
  @apply bg-blue text-white;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button:hover {
  @apply bg-blend-screen;
  color: #000;
}
